<template>
  <div class="socials-icons">
    <component
      :is="sharePage ? 'div' : 'a'"
      href="https://www.facebook.com/stockprofrance/"
      :title="sharePage ? $t('social.shareOn', { social: 'Facebook' }) : $t('social.followOn', { social: 'Facebook' })"
      data-test-id="footer-social-facebook"
      target="_blank"
      class="facebook"
      @click="sharePage ? shareLink('Facebook') : null"
    >
      <div class="icon-social-footer-facebook u-bg-social-icons" />
    </component>
    <a
      v-if="!sharePage"
      href="https://www.instagram.com/stockpro_france/"
      :title="$t('social.followOn', { social: 'Instagram' })"
      data-test-id="footer-social-instagram"
      target="_blank"
      class="instagram"
    >
      <div class="icon-social-footer-instagram u-bg-social-icons" />
    </a>
    <component
      :is="sharePage ? 'div' : 'a'"
      href="https://www.linkedin.com/company/stockpro/"
      :title="sharePage ? $t('social.shareOn', { social: 'LinkedIn' }) : $t('social.followOn', { social: 'LinkedIn' })"
      data-test-id="footer-social-linkedIn"
      target="_blank"
      class="linkedin"
      @click="sharePage ? shareLink('LinkedIn') : null"
    >
      <div class="icon-social-footer-linkedin u-bg-social-icons" />
    </component>
    <component
      :is="sharePage ? 'div' : 'a'"
      href="https://twitter.com/FranceStockPro/"
      :title="sharePage ? $t('social.shareOn', { social: 'Twitter' }) : $t('social.followOn', { social: 'Twitter' })"
      data-test-id="footer-social-twitter"
      target="_blank"
      class="twitter"
      @click="sharePage ? shareLink('Twitter') : null"
    >
      <div class="icon-social-footer-twitter u-bg-social-icons" />
    </component>
    <a
      v-if="!sharePage"
      href="https://www.youtube.com/channel/UCTvHKa_JcH4Oyn41h7qB7Tg"
      :title="$t('social.followOn', { social: 'Youtube' })"
      data-test-id="footer-social-youtube"
      target="_blank"
      class="youtube"
    >
      <div class="icon-social-footer-youtube u-bg-social-icons" />
    </a>
    <component :is="sharePage ? 'div' : 'a'" v-if="displayLink" :title="$t('social.shareLink')" @click="copyLink">
      <div class="icon-link u-bg-social-icons" />
    </component>
  </div>
</template>

<script>
import Mobile from '@/mixins/mobile-mixin'
export default {
  name: 'SocialIcons',
  mixins: [Mobile],
  props: {
    displayLink: { type: Boolean, default: false },
    sharePage: { type: Boolean, default: false },
    metaData: { type: Object, default: () => {} },
  },
  methods: {
    copyLink() {
      const dummy = document.createElement('input')
      const text = location.href
      document.body.appendChild(dummy)
      dummy.value = text
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)

      this.$store.dispatch('snackbar/show', {
        color: 'primary',
        message: this.$t('snackbar.linkCopied'),
      })
    },
    shareLink(type) {
      let url = null
      const linkTo = location.href
      const body = encodeURI(
        this.$t('share.blog', {
          name: this.metaData.title,
          link: linkTo,
        })
      )

      if (type === 'Facebook') url = `https://www.facebook.com/sharer/sharer.php?u=${linkTo}&p[title]=${body}`
      if (type === 'Twitter') url = `http://twitter.com/share?url=${linkTo}&text=${body}`
      if (type === 'LinkedIn') url = `https://www.linkedin.com/shareArticle?mini=true&url=${linkTo}&title=${body}`
      console.log(url)
      if (url) {
        if (this.isMd) {
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          window.open(
            url,
            type,
            'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600'
          )
        }
      }
    },
  },
}
</script>

<style lang="scss">
.socials-icons {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-md;
  @include mq($mq-sm) {
    margin-bottom: 20px;
  }

  a,
  div {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform var(--transition-duration) var(--transition-timing-function);
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
    }

    &:first-child {
      @include mq($mq-sm) {
        margin-left: 0;
      }
    }
  }
}
</style>
